<ul class="nav nav-tabs" >

  <li class="nav-item" >
    <button class="btn dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
      Ver eventos sísmicos entre fechas
    </button>
    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton" >
      <li>
        <form>
          <p> Fecha inicial: 
            <input type="date" name="fecha_inicial" id="fecha_inicial">
          </p>
          <P> Fecha final:
            <input type="date" name="fecha_final" id="fecha_final">
          </P>
          <p>
          <a class="btn-group" role="group" aria-label="Basic example">
            <button type="button" class="btn btn-secondary" role="button" href="javascript:download_between_dates();" download> Descargar</button>
            <button type="button" class="btn btn-secondary" id="CSV">Ver</button>
            <button type="button" class="btn btn-secondary"id= "mode" style="display:none;"></button>
          </a>
          </p>
            <div id="error_busqueda" style="display:none;" class="alert alert-warning alert-dismissible fade show" role="alert">
              <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
            </div>
            <div id="success_busqueda" style="display:none;" class="alert alert-success" role="alert">

            </div>
        </form>
        
      </li>

    </ul>


  
  </li>


</ul>