import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';

import { NavbarComponent } from './navbar/navbar.component';
import {Routes, RouterModule} from '@angular/router';

const appRoutes: Routes=[
	{path:'',redirectTo:'/',pathMatch:'full'}
];
@NgModule({
  declarations: [
    AppComponent,
    
    NavbarComponent,
    
  ],
  imports: [
    BrowserModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
